export default class flipCatWraps {
    constructor(el) {
        this.catWraps = el;
        this.init = this.init.bind(this);
    }

    init() {
        if(window.innerWidth < 786) {
            setTimeout(function(){
                $('.nav > .logo').on('click', function(e){
                    e.preventDefault();
                }).addClass('txt').find('h1').text($('.nav-items a.active').text());
            }, $('.page-home').length > 0 ? 2000 : 500);
        }

        $(this.catWraps).each((idx, el) => {
            let id = $(el).attr('id');
            $('.nav-items').find(`[class*="${id}"]`).on('click', (e) => {
                if(!e.altKey && !e.ctrlKey && !e.shiftKey && !e.metaKey){
                    e.preventDefault();

                    if(window.innerWidth < 786) {
                        $('.nav').removeClass('active');
                        $('.nav > .logo').on('click', function(e){
                            e.preventDefault();
                        }).addClass('txt').find('h1').text($(e.target).text());
                    }

                    if($(el).hasClass('active')) {
                        $('html,body').animate({
                            scrollTop: 0
                        }, 400);
                        return;
                    }

                    let cw = this.catWraps;

                    setTimeout(function(){
                        $(cw).removeClass('active');
                        $(el).addClass('active');
                        $('.nav-items .active').removeClass('active');
                        $(e.target).addClass('active');
                        window.history.pushState({}, '', $(e.target).attr('href'));
                    }, 10);
                }    
            });
        });

        window.onpopstate = function(event) {
            let url = window.location.href;
            let id = url.split('/').pop();
            if(id=='') { id = 'products'; }
            $('.nav-items .active').removeClass('active');
            $('.nav-items').find(`[class*="${id}"]`).addClass('active');
            $('.nav').removeClass('active');
            setTimeout(function(){
                $('.cat-wrap').removeClass('active');
                $(`[id="${id}"]`).addClass('active');
                $('.nav > .logo').on('click', function(e){
                    e.preventDefault();
                }).addClass('txt').find('h1').text(id.split('')[0].toUpperCase() + id.slice(1));
            },10);
        };
    }
}