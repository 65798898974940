export default function projectCardsParallax(parents) {
    // console.log('ok projectcards');
    // console.log('scroll thing inited');
    const scrollSpeed = 0.4; // Adjust this value to change the parallax speed
    var figures = [];
   
    parents.forEach((parent, idx) => {
        // find .featureimage img and add it to figures
        let img = parent.querySelector('.featureimage img');
        figures.push(img);
        parent.img = img;
    });

    let scrollPosition = window.scrollY;
    let lastKnownScrollPosition = 0.001;
    let ticking = false;
    
    function doParallax() {
        const delta = scrollPosition - lastKnownScrollPosition;
        lastKnownScrollPosition = scrollPosition;


        if(!parents[0].parentNode.parentNode.classList.contains('active')){
            // console.log('canceliong');
            return;
        }

        parents.forEach((parent, idx) => {

            // check if any parents have class .active

        // figures.forEach((img) => {
            // find parent .project-card--opener
            // const imgParentRect = parent.getBoundingClientRect();
            // // const imgParentRect = img.parentElement.getBoundingClientRect();
            
            // if (imgParentRect.bottom < 0 || imgParentRect.top > window.innerHeight) {
            //     return;
            // }

            const img = parent.img;

            const imgRect = img.getBoundingClientRect();
            if (imgRect.bottom < 0 || imgRect.top > window.innerHeight) {
                return;
            }

            const imgCenterY = imgRect.top + imgRect.height / 2;
            const screenCenterY = window.innerHeight / 2;
            const distanceFromCenter = imgCenterY - screenCenterY;

            // Normalizing the distance from the center to the range of -1 to 1
            const maxDistance = window.innerHeight / 2 + imgRect.height / 2;
            const normalizedDistance = Math.min(Math.max(distanceFromCenter / maxDistance, -1), 1);

            // Calculating the offset where maximum offset is n pixels either up or down
            const offset = (-normalizedDistance) * imgRect.height * scrollSpeed;
            // const offset = (-normalizedDistance) * -100;
            img.style.transform = `translateY(${offset}px)`;
        });
    }

    let tickTimeout = null;
    
    window.addEventListener('scroll', function(e) {
        if (!ticking) {
            clearTimeout(tickTimeout);
            tickTimeout = setTimeout(() => {
                // window.requestAnimationFrame(function() {
                    scrollPosition = window.scrollY;
                    doParallax();
                    ticking = false;
                // });
            }, 1000 / 60);
            
            ticking = true;
        }
    });

    doParallax();
}