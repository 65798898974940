export default class project {
    constructor(el) {
        this.domEl = el;
        console.log(el);
        this.init = this.init.bind(this);

        $('[data-hires-srcset]').each(function(){
            console.log('setting srcset', el);
            $(this).attr('srcset', $(this).data('hires-srcset'));
            $(this).removeAttr('data-hires-srcset');
        });
    }

    init() {
        this.initSlides();
    }

    initSlides(){
        if(window['projectanims']){
        for(var i=0; i<window['projectanims'].length; i++){
            window.clearInterval(window.projectanims[i]);
        }
        }
        window.projectanims = [];
        $('[data-duration]').each(function(idx){
        var id = $(this).attr('id');
        if(id==undefined) {
            id = $(this).parents('.imageset').attr('id');
        }
        var duration = $(this).attr('data-duration')*1000;
    
        var container = this;
        var interval_id = `intervals_${id}`;
        // window.clearInterval(window.projectanims[interval_id]);
        window.projectanims[idx] = setInterval(function(){
            var curAct = container.querySelector('.act');
            if(curAct){
            var next = curAct.nextElementSibling;
            if(!next) { next = container.querySelector('li'); }
            curAct.classList.remove('act');
            next.classList.add('act');
            }
        }, duration);
        });
    }
}