global.$ = require('jquery');
global.remToPx = function(rem) {    
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}
import flipCatWraps from './parts/flipCatWraps';
import project from './parts/project';
import logoSizer from './parts/logoSizer';
import projectCardsParallax from './parts/projectCardsParallax';
import projectCardsAjax from './parts/projectCardsAjax';

window.pcParallax = projectCardsParallax;
window.pcAjax = projectCardsAjax;

$('#loader img').addClass('anim');

window.fontsLoadedInterval = setInterval(function(){
    if(
        document.fonts.check('1em abcsyntmono') &&
        document.fonts.check('1em tempelgrotesk') &&
        document.fonts.check('1em millionaire')
    ){
        clearInterval(window.fontsLoadedInterval);
        $('html').addClass('t-ok');

        setTimeout(function(){
            $('html').addClass('cp-ok');
        }, 1500);
        // document.documentElement.classList.add('fonts-loaded');
    }
}, 100);

$(function(){
    global.isTouch = (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));

    checkSidebySide();

    if($('.page-home').length > 0){
        new logoSizer();
    }

    if($('.cat-wrap').length > 1){
        let cw = new flipCatWraps($('.cat-wrap'));
        cw.init();
    }

    if($('.project-card').length > 1){ // not for single project
        let parallaxElements = document.querySelectorAll('.project-card--opener');
        projectCardsParallax(parallaxElements);
        
        let createNewPcAjax = new projectCardsAjax($('.project-card').toArray());
        // pcAjax.init();
    }
    
    if($('.page-project').length>0) {
        $('.project-card').addClass('active').on('click', function(e) { e.preventDefault() });
        let p = new project($('article.project-entry'));
        p.init();
    }

    let ticking = false;

    function spinThings(delta){
        $('[data-srcset]').each(function(){
            if($(this).offset().top < window.innerHeight*2 + window.scrollY){
                $(this).attr('srcset', $(this).data('srcset'));
                $(this).removeAttr('data-srcset');
            }
        });
        
        $('[data-src]').each(function(){
            if($(this).offset().top < window.innerHeight*2 + window.scrollY){
                $(this).attr('src', $(this).data('src'));
                $(this).removeAttr('data-src');
            }
        });
        

        $('.active .symbol img, .nav-toggle .symbol img, .enable .rot').each(function(){
            // rotate according to scroll position
            let curRot = parseFloat(this.dataset.rotation) || 0;
            let newRot = curRot + (delta * 0.5);
            $(this).css('transform', `rotate(${newRot}deg)`);
            this.dataset.rotation = newRot;
        });
    }

    let scrollPosition = window.scrollY;
    let lastKnownScrollPosition = 0.001;
    let rafTime = null;
    
    $(window).on('scroll', function(){
        const delta = scrollPosition - lastKnownScrollPosition;
        lastKnownScrollPosition = scrollPosition;

        if (!ticking) {
            window.requestAnimationFrame(function() {
                spinThings(delta);
                clearTimeout(rafTime);
                // rafTime = setTimeout(function(){
                    ticking = false;
                // },10);
            });
            
            ticking = true;
        }
        scrollPosition = window.scrollY;
    });

    $('.nav-toggle').on('click', function(e){
        e.preventDefault();
        $('.nav').toggleClass('active');
        let span = $(this).find('span');
        span.text(span.text() == 'Close' ? 'Menu' : 'Close');
    });

    $(window).trigger('scroll');
});

function checkSidebySide() {
    // check .block-sidebyside and if .left contains a .block[class*=text] and .right doesnt, add class
    $('.block-side-by-side').each(function(){
        let left = $(this).find('.left');
        let right = $(this).find('.right');
        if(!left.find('.block[class*="text"]').length > 0 && right.find('.block[class*="text"]').length > 0){
            $(this).addClass('mob-flip');
        }
        if(left.find('.block[class*="text"]').length > 0 && right.find('.block[class*="text"]').length > 0){
            $(this).addClass('both-text');
        }
    });
}