export default class logoSizer {
   constructor(){
      const TOP_OFFSET = window.innerWidth * 0.15;
      const vwSize = 7.975;
      let scrollPosition = 0;
      let ticking = false;
      
      function size() {
         let navBar = document.querySelector('.nav');
         if(window.scrollY > TOP_OFFSET){
            if(navBar.getBoundingClientRect().top >= 0) {
               let distance = (navBar.getBoundingClientRect().top) / ($('.nav').position().top - TOP_OFFSET);
               // console.log(distance);
               $('.logo img').css({
                  'height' : `calc(${vwSize * distance}vw + ${(1-distance)*remToPx(1)}px)`
               });
            } else {
               $('.logo img').css({
                  'height' : `${remToPx(1)}px`
               });
            }
         } else if (window.scrollY <= TOP_OFFSET){
            $('.logo img').css({
               'height' : `${vwSize}vw`
            })
         }
      }
      
      window.addEventListener('scroll', function(e) {
         scrollPosition = window.scrollY;
         
         if (!ticking) {
            window.requestAnimationFrame(function() {
               size();
               ticking = false;
            }, 0);
            
            ticking = true;
         }
      });
      size();
   }
}