export default class projectCardsAjax {
    constructor(els) {
        // this.init = this.init.bind(this);

        els.forEach(el => {
            this.initLink($(el).find('.project-card--opener'));
        });

        window.addEventListener('scroll', () => {
            // find active project card, if 50% from top or bottom of the screen, hide the close button
            let actives = document.querySelectorAll('.project-card.active');
            if(actives) {
                const LIMIT = window.innerWidth >= 786 ? window.innerHeight/2 : 0;
                actives.forEach((active) => {
                    let top = active.getBoundingClientRect().top;
                    let bottom = active.getBoundingClientRect().bottom;
                    let height = window.innerHeight;
                    let close = active.querySelector('.project-card--close');
                    if(top < LIMIT && bottom > LIMIT) {
                        $(close).removeClass('hidden')
                    } else {
                        $(close).addClass('hidden')
                    }
                });
            }
        });
        

        $('.project-card--close').on('click', function(e) {
            e.preventDefault();
            let target = $(this).parents('.project-card');
            let content = target.find('.project-card--content');
            let title = target.find('.project-card--opener h2');

            if(target.hasClass('loading')) return;
            clearTimeout(window.openTimeout);

            target.removeClass('active');
            content.stop().slideUp(500);
            title.removeClass('big');

            if(!isTouch) {
                $('html,body').animate({
                    scrollTop: target.offset().top - window.innerHeight/2
                }, 400);
            }
        });
    }

    initLink(link) {
        $(link).on('click', function(e) {
            if(e.metaKey || e.ctrlKey || e.shiftKey || e.altKey) return;
            e.preventDefault();
            // console.log('prevented');
            let url = $(this).attr('href');
            let target = $(this).parents('.project-card');
            let container = target.find('.project-card--content');
            let content = target.find('.project-card--content');
            let title = target.find('.project-card--opener h2');
            let close = target.find('.project-card--close');

            if(target.hasClass('loading')) return;
            
            // close other open projects
            let thereIsActive = false;

            $('.project-card').not(target).each((idx, el) => {
                let elTarget = $(el);
                let elContent = elTarget.find('.project-card--content');
                let elTitle = elTarget.find('.project-card--opener h2');
                let elClose = elTarget.find('.project-card--close');
                if(elTarget.hasClass('active')) {
                    elTarget.removeClass('active');
                    elContent.stop().slideUp(200);
                    elTitle.removeClass('big');
                    elClose.addClass('hidden');
                    thereIsActive = true;
                }
            });

            if(!target.hasClass('loaded')) {
                
                target.addClass('loading active');
                content.addClass('hidden');
                content.hide();
                
                // if(!isTouch){
                    setTimeout(() => {
                        $('html,body').animate({
                            scrollTop: target.offset().top
                        }, thereIsActive ? 400 : 400, function(){
                            if(thereIsActive) {
                                $('html,body').animate({
                                    scrollTop: target.offset().top
                                }, 400);
                            }
                        });
                    }, thereIsActive ? 205 : 0);
                // }
                
                container.load(url + ' .project-entry', function(response, status, xhr) {
                    if (status == 'success') {                        
                        target.addClass('loaded');

                        title.addClass('big');

                        content.removeClass('hidden');

                        window.openTimeout = setTimeout(() => {
                            target.removeClass('loading');
                            content.slideDown(1200, function(){
                                close.addClass('enable');
                            });
                            $('html,body').animate({
                                scrollTop: target.offset().top
                            }, 400);

                            target.find('[data-hires-srcset]').each(function(){
                                console.log('setting srcset', this);
                                $(this).attr('srcset', $(this).data('hires-srcset'));
                                $(this).removeAttr('data-hires-srcset');
                            });
                        }, 400);

                        content.find('[data-srcset]').each(function(){
                            $(this).attr('srcset', $(this).data('srcset'));
                            $(this).removeAttr('data-srcset');
                        });
                        content.find('[data-src]').each(function(){
                            $(this).attr('src', $(this).data('src'));
                            $(this).removeAttr('data-src');
                        });                        

                        $(window).trigger('scroll');
                    }
                });
            } else {
                target.toggleClass('active');
                content.toggleClass('hidden');
                title.toggleClass('big');

                if(target.hasClass('active')){
                    // if(!isTouch){
                        setTimeout(() => {
                            $('html,body').animate({
                                scrollTop: target.offset().top
                            }, thereIsActive ? 400 : 400, function(){
                                if(thereIsActive) {
                                    $('html,body').animate({
                                        scrollTop: target.offset().top
                                    }, 400);
                                }
                            });
                        }, thereIsActive ? 205 : 0);
                    // }

                    window.openTimeout = setTimeout(() => {
                        content.stop().slideDown(1200, function(){
                            close.addClass('enable');
                        });
                    }, 400);

                } else {
                    content.css({
                        'max-height' : '100vh'
                    });
                    window.openTimeout = setTimeout(() => {
                        content.stop().slideUp(500, function(){
                            close.removeClass('enable');
                        });
                    }, 1);
                    // content.hide();
                    title.removeClass('big');
                }
            }
        });
    }
}